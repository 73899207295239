import React, { useState } from "react";
import PropTypes from "prop-types";
import EmailQuoteModal from "./EmailQuoteModal";
import InfoBox from "./InfoBox";
import backArrowIcon from "../images/back-arrow-icon-white.png";
import emailIcon from "../images/email-icon-white.png";
import * as payments from "../modules/payments";
import QuestLogo from "./QuestLogo";

const LoanSummary = ({ onBack, formData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const parseAmount = (value) => {
    if (typeof value === "string") {
      return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
    }
    return parseFloat(value) || 0;
  };

  const purchasePrice = parseAmount(formData.purchasePrice);
  const cashDeposit = parseAmount(formData.cashDeposit);
  const tradeIn = parseAmount(formData.tradeIn);
  const payoutAmount = parseAmount(formData.payoutAmount);
  const brokerage = parseAmount(formData.brokerage);
  const brokerageWithGST = brokerage * 1.1;
  const originationFee = parseAmount(formData.brokerOriginationFee);
  const originationFeeWithGST = originationFee * 1.1;
  const applicationFee = parseAmount(formData.loanApplicationFee);
  const applicationFeeWithGST = applicationFee * 1.1;
  let netAmountFinanced =
    purchasePrice -
    cashDeposit -
    tradeIn +
    payoutAmount +
    (formData.financeOriginationFee ? originationFeeWithGST : 0) +
    (formData.financeApplicationFee ? applicationFeeWithGST : 0);

  const [emailAddress, setEmailAddress] = useState("");

  const formatMoney = (amount) => {
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(amount);
  };

  const financierRate = parseAmount(formData.interestRate);
  const term = parseInt(formData.loanTerm) || 1;
  const paymentCycle = formData.paymentFrequency;
  const paymentTime = formData.paymentTiming;

  const totalLoanAmount = netAmountFinanced + brokerageWithGST;

  let balloonPayment = 0;
  if (formData.balloonPaymentPercentage !== "") {
    const balloonPercentage = parseAmount(formData.balloonPaymentPercentage);
    balloonPayment = (balloonPercentage / 100) * purchasePrice;
  } else {
    balloonPayment = parseAmount(formData.balloonPayment);
  }

  const paymentsPerYear = paymentCycle === "Monthly" ? 12 : 52;
  const totalPayments = term * paymentsPerYear;
  const ratePerPeriod = financierRate / 100 / paymentsPerYear;

  let paymentTiming = 0;
  if (paymentTime === "Advance") {
    paymentTiming = 1;
  } else {
    paymentTiming = 0;
  }
  const repayment = payments.pmt(
    ratePerPeriod,
    totalPayments,
    -totalLoanAmount,
    balloonPayment,
    paymentTiming,
  );
  const firstInstallment =
    repayment +
    (!formData.financeOriginationFee ? originationFeeWithGST : 0) +
    (!formData.financeApplicationFee ? applicationFeeWithGST : 0);

  let interestSum = 0;
  for (let period = 0; period <= totalPayments; period++) {
    interestSum += Math.abs(
      payments.ipmt(ratePerPeriod, period, totalPayments, -totalLoanAmount),
    );
  }
  const questCommission = interestSum * (0.03 / (financierRate / 100));

  const BrokerCommission = interestSum - questCommission;

  return (
    <div className="iphone">
      <div
        className="loan-summary-page"
        style={{
          backgroundColor: "#007BFF",
          padding: "20px",
          minHeight: "100vh",
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={backArrowIcon}
            alt="Back"
            onClick={onBack}
            style={{ width: 26, height: 26, cursor: "pointer" }}
          />
        </div>

        <QuestLogo purpleLogo={false} />
        <div
          className="loan-summary-container"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <h2
            style={{
              color: "#3D1C80",
              textAlign: "center",
              fontSize: "24px",
              marginBottom: "20px",
            }}
          >
            Quote Summary
          </h2>

          <div className="section" style={{ marginBottom: "20px" }}>
            <div className="summary-item">
              <span>Purchase Price</span>
              <span style={{ color: "#3D1C80" }}>
                {formatMoney(purchasePrice)}
              </span>
            </div>
            <div className="summary-item">
              <span>Less Deposit</span>
              <span>{formatMoney(cashDeposit)}</span>
            </div>
            <div className="summary-item">
              <span>Less Trade In</span>
              <span>{formatMoney(tradeIn)}</span>
            </div>
            <div className="summary-item">
              <span>Plus Payout</span>
              <span>{formatMoney(payoutAmount)}</span>
            </div>
            {(formData.financeApplicationFee ||
              formData.financeOriginationFee) && <div className="divider" />}
            {formData.financeApplicationFee && (
              <div className="summary-item">
                <span>Application Fee (incl. GST) </span>
                <span style={{ color: "#3D1C80" }}>
                  {formatMoney(applicationFeeWithGST)}
                </span>
              </div>
            )}
            {formData.financeOriginationFee && (
              <div className="summary-item">
                <span>Origination Fee (incl. GST) </span>
                <span style={{ color: "#3D1C80" }}>
                  {formatMoney(originationFeeWithGST)}
                </span>
              </div>
            )}
            <div className="divider" />
            <div className="summary-item text-bold">
              <span>Net Amount Financed</span>
              <span style={{ color: "#3D1C80" }}>
                {formatMoney(netAmountFinanced)}
              </span>
            </div>
            <div className="summary-item">
              <span>{paymentCycle} Payments </span>
              <span style={{ color: "#3D1C80" }}>{formatMoney(repayment)}</span>
            </div>
            <div className="summary-item">
              <span>First Instalment</span>
              <span style={{ color: "#3D1C80" }}>
                {formatMoney(firstInstallment)}
              </span>
            </div>
            <div className="summary-item">
              <span>Brokerage (incl. GST)</span>
              <span style={{ color: "#3D1C80" }}>
                {formatMoney(brokerageWithGST)}
              </span>
            </div>
          </div>
        </div>
        <InfoBox
          title="Important Information"
          content="This summary provides an overview of the loan details. Please review carefully before proceeding."
          backgroundColor="#f8f9fa"
          totalCommission={formatMoney(BrokerCommission.toFixed(2))}
        />
        <button
          className="email-quote-button"
          onClick={openModal}
          style={{
            backgroundColor: "#05dd80",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <img
            src={emailIcon}
            alt="Email Icon"
            style={{ width: 26, height: 26, marginRight: 10 }}
          />
          Email Quote
        </button>
        <EmailQuoteModal
          onClose={closeModal}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
          formJson={{
            ...formData,
            loanApplicationFeeWithGST: applicationFeeWithGST,
            originationFeeWithGST,
            financeBrokerageFee: formData.financeOriginationFee,
            netAmountFinanced,
            repayment,
            brokerageWithGST,
            balloonPayment,
            paymentTime,
            firstInstallment,
          }}
        />
      </div>
    </div>
  );
};

LoanSummary.propTypes = {
  onBack: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default LoanSummary;
