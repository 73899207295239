import React, { useState } from "react";
import PropTypes from "prop-types";
import EmailQuoteModal from "./EmailQuoteModal";
import InfoBox from "./InfoBox";
import * as payments from "../modules/payments";

const QuoteSummary = ({ formData }) => {
  QuoteSummary.propTypes = {
    formData: PropTypes.object.isRequired,
    brokerageAmount: PropTypes.number,
    netAmountFinanced: PropTypes.number,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [emailAddress, setEmailAddress] = useState("");

  const parseAmount = (value) => {
    if (typeof value === "string") {
      // Remove all non-numeric characters except for the decimal point
      return parseFloat(value.replace(/[^0-9.]/g, "")) || 0;
    }
    return parseFloat(value) || 0;
  };

  const purchasePrice = parseAmount(formData.purchasePrice);
  const cashDeposit = parseAmount(formData.cashDeposit);
  const tradeIn = parseAmount(formData.tradeIn);
  const payoutAmount = parseAmount(formData.payoutAmount);
  const balloonAmount = parseAmount(formData.balloonAmount);
  const brokerageAmount = parseAmount(formData.brokerageAmount);
  const financierRate = parseAmount(formData.financierRate);
  const term = parseInt(formData.term) || 1;
  const paymentCycle = formData.paymentCycle || "Monthly";
  const paymentTime = formData.paymentTime || "Arrears";
  const originationFee = parseAmount(formData.brokerOriginationFees);
  const originationFeeWithGST = originationFee * 1.1;
  const loanApplicationFee = parseAmount(formData.loanApplicationFee);
  const loanApplicationFeeWithGST = loanApplicationFee * 1.1;

  let netAmountFinanced =
    purchasePrice -
    cashDeposit -
    tradeIn +
    payoutAmount +
    (formData.financeBrokerageFee ? originationFeeWithGST : 0) +
    (formData.financeApplicationFee ? loanApplicationFeeWithGST : 0);
  const brokerageWithGST = brokerageAmount * 1.1;

  const totalLoanAmount = netAmountFinanced + brokerageWithGST;
  const paymentsPerYear = paymentCycle === "Monthly" ? 12 : 52;
  const totalPayments = term * paymentsPerYear;
  const ratePerPeriod = financierRate / 100 / paymentsPerYear;
  const timing = paymentTime === "Arrears" ? 0 : 1;
  const repayment =
    payments.pmt(
      ratePerPeriod,
      totalPayments,
      -totalLoanAmount,
      balloonAmount,
      timing,
    ) || 0;
  const firstInstallment =
    repayment +
    (!formData.financeBrokerageFee ? originationFeeWithGST : 0) +
    (!formData.financeApplicationFee ? loanApplicationFeeWithGST : 0);

  let interestSum = 0;
  for (let period = 0; period <= totalPayments; period++) {
    interestSum += Math.abs(
      payments.ipmt(ratePerPeriod, period, totalPayments, -totalLoanAmount),
    );
  }
  const questEarnings = interestSum * (0.03 / (financierRate / 100)) || 0;
  const commisionToBroker = (() => {
    const result = interestSum - questEarnings;
    return isNaN(result) ? 0 : result;
  })();

  const formatMoney = (amount) => {
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(amount);
  };

  return (
    <div className="quote-summary">
      <h2 style={{ color: "#3D1C80" }}>Quote Summary</h2>
      <div className="summary-item">
        <span>Purchase Price</span>
        <span style={{ color: "#3D1C80" }}>{formatMoney(purchasePrice)}</span>
      </div>
      <div className="summary-item">
        <span>Less Deposit</span>
        <span style={{ color: "#3D1C80" }}>{formatMoney(cashDeposit)}</span>
      </div>
      <div className="summary-item">
        <span>Less Trade In</span>
        <span>{formatMoney(tradeIn)}</span>
      </div>
      <div className="summary-item">
        <span>Plus Payout</span>
        <span>{formatMoney(payoutAmount)}</span>
      </div>
      {(formData.financeBrokerageFee || formData.financeApplicationFee) && (
        <div className="divider" />
      )}
      {formData.financeApplicationFee && (
        <div className="summary-item">
          <span>Application Fee (incl. GST)</span>
          <span style={{ color: "#3D1C80" }}>
            {formatMoney(loanApplicationFeeWithGST)}
          </span>
        </div>
      )}
      {formData.financeBrokerageFee && (
        <div className="summary-item">
          <span>Origination Fee (incl. GST)</span>
          <span style={{ color: "#3D1C80" }}>
            {formatMoney(originationFeeWithGST)}
          </span>
        </div>
      )}
      <div className="divider" />
      <div className="summary-item text-bold">
        <span>Net Amount Financed</span>
        <span style={{ color: "#3D1C80" }}>
          {formatMoney(netAmountFinanced)}
        </span>
      </div>
      <div className="summary-item">
        <span>{paymentCycle} Payments</span>
        <span style={{ color: "#3D1C80" }}>{formatMoney(repayment)}</span>
      </div>
      <div className="summary-item">
        <span>First Instalment</span>
        <span style={{ color: "#3D1C80" }}>
          {formatMoney(firstInstallment)}
        </span>
      </div>
      <div className="summary-item">
        <span>Brokerage (incl. GST)</span>
        <span style={{ color: "#3D1C80" }}>
          {formatMoney(brokerageWithGST)}
        </span>
      </div>
      <InfoBox
        formData={formData}
        totalCommission={
          commisionToBroker === 0
            ? "$0.00"
            : Number(commisionToBroker.toFixed(2))
        }
      />
      <button
        className="email-quote-button"
        onClick={openModal}
        style={{
          backgroundColor: "#05dd80",
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
          width: "100%",
        }}
      >
        Email Quote
      </button>

      <EmailQuoteModal
        isOpen={isModalOpen}
        onClose={closeModal}
        emailAddress={emailAddress}
        setEmailAddress={setEmailAddress}
        formJson={{
          ...formData,
          netAmountFinanced,
          originationFeeWithGST,
          loanApplicationFeeWithGST,
          brokerageWithGST,
          balloonPayment: balloonAmount,
          paymentTime,
          repayment,
          firstInstallment,
        }}
      />
    </div>
  );
};

export default QuoteSummary;
