import React, { useState, useEffect, useCallback } from "react";
import AssetFinanceCalculator from "./components/AssetFinanceCalculator";
import Fees from "./components/Fees";
import LoanSummary from "./components/LoanSummary";
import Payments from "./components/Payments";
import Desktop from "./desktop";

function LoanCalculatorV3() {
  const [showPayments, setShowPayments] = useState(false);
  const [showFees, setShowFees] = useState(false);
  const [showLoanSummary, setShowLoanSummary] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const [formData, setFormData] = useState({
    // Asset Finance Calculator fields
    purchasePrice: "",
    cashDeposit: "",
    tradeIn: "",
    payoutAmount: "",
    interestRate: "9",
    // Payments fields
    loanTerm: "5",
    balloonType: "",
    balloonPayment: "",
    balloonPaymentPercentage: "",
    paymentFrequency: "Monthly",
    paymentTiming: "Advance",
    // Fees fields
    brokerageType: "",
    brokerage: "",
    brokeragePercentage: "",
    financeOriginationFee: false,
    brokerOriginationFee: "",
    financeApplicationFee: false,
    loanApplicationFee: "",
  });
  const [navigationStack, setNavigationStack] = useState(["calculator"]);

  // Handler for updating form data
  const handleFormDataChange = useCallback(
    (newData) => {
      setFormData((prevData) => {
        return { ...prevData, ...newData };
      });
    },
    [setFormData],
  );

  // Handlers for page visibility
  const handleShowPayments = () => {
    setShowPayments(true);
    setShowFees(false);
    setShowLoanSummary(false);
    setNavigationStack((prev) => [...prev, "payments"]);
    window.scrollTo(0, 0); 
  };

  // const handleHidePayments = () => {
  //   setShowPayments(false);
  // };

  const handleShowFees = () => {
    setShowFees(true);
    setShowPayments(false);
    setShowLoanSummary(false);
    setNavigationStack((prev) => [...prev, "fees"]);
    window.scrollTo(0, 0); 
  };

  // const handleHideFees = () => {
  //   setShowFees(false);
  //   setShowPayments(true);
  // };

  const handleShowLoanSummary = () => {
    setShowLoanSummary(true);
    setShowFees(false);
    setShowPayments(false);
    setNavigationStack((prev) => [...prev, "summary"]);
    window.scrollTo(0, 0); 
  };

  // const handleHideLoanSummary = () => {
  //   setShowLoanSummary(false);
  //   setShowFees(true);
  // };

  const handleBack = () => {
    if (navigationStack.length > 1) {
      const newStack = [...navigationStack];
      newStack.pop();
      setNavigationStack(newStack);
      const previousScreen = newStack[newStack.length - 1];

      setShowPayments(previousScreen === "payments");
      setShowFees(previousScreen === "fees");
      setShowLoanSummary(previousScreen === "summary");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="app-container quote-calc">
      {isDesktop && <Desktop />}

      {!isDesktop && (
        <>
          {navigationStack[navigationStack.length - 1] === "calculator" && (
            <AssetFinanceCalculator
              onShowPayments={handleShowPayments}
              formData={formData}
              onFormDataChange={handleFormDataChange}
            />
          )}

          {showPayments && (
            <div id="payments">
              <Payments
                onBack={handleBack}
                onShowFees={handleShowFees}
                formData={formData}
                onFormDataChange={handleFormDataChange}
              />
            </div>
          )}

          {showFees && (
            <div id="fees">
              <Fees
                onBack={handleBack}
                onShowLoanSummary={handleShowLoanSummary}
                formData={formData}
                onFormDataChange={setFormData}
              />
            </div>
          )}

          {showLoanSummary && (
            <div id="loan-summary">
              <LoanSummary onBack={handleBack} formData={formData} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LoanCalculatorV3;
