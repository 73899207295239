import React from "react";

const QuestLogo = ({ purpleLogo = true }) => {
  const logoSrc = purpleLogo
    ? "/assets/images/quest-logo.svg"
    : "/assets/images/quest-logo-white.svg";

  return (
    <div className="quest-logo">
      <a href="https://quest.finance">
        <img src={logoSrc} alt="Quest Finance" />
      </a>
    </div>
  );
};

export default QuestLogo;
