import React, { useCallback, useEffect, useState } from "react";
import "./desktop.css";
import InputField from "./components/InputField";
import QuoteSummary from "./components/QuoteSummary";
import { FieldCalculationType } from "../shared/constants";
import QuestLogo from "./components/QuestLogo";

function Desktop() {
  const [formData, setFormData] = useState({
    purchasePrice: "",
    cashDeposit: "",
    tradeIn: "",
    payoutAmount: "",
    financierRate: "9.00",
    brokerOriginationFees: "",
    loanApplicationFee: "",
    term: "5",
    paymentCycle: "Monthly",
    paymentTime: "Advance",
    financeBrokerageFee: false,
    financeApplicationFee: false,
    commisionToBroker: 0,
    balloonPercentage: "",
    balloonAmount: "",
    balloonType: "",
    brokeragePercentage: "",
    brokerageAmount: "",
    brokerageType: "",
  });
  const purchasePrice =
    parseFloat(formData.purchasePrice.replace(/[^0-9.-]+/g, "")) || 0;

  const [baseLoanAmount, setBaseLoanAmount] = useState(0);
  const [netAmountFinanced, setNetAmoundFinanced] = useState(0);

  const handleInputChange = (eventOrName, valueOrChecked) => {
    let name;
    if (typeof eventOrName === "string") {
      name = eventOrName;
    } else {
      return;
    }

    setFormData((prev) => ({ ...prev, [name]: valueOrChecked }));
  };

  const calculateBalloon = useCallback(() => {
    if (purchasePrice) {
      if (formData.balloonType === FieldCalculationType.PERCENTAGE) {
        const balloonPercentage = formData.balloonPercentage || 0;

        setFormData((state) => ({
          ...state,
          balloonAmount: ((balloonPercentage / 100) * purchasePrice).toFixed(2),
        }));
      }

      if (formData.balloonType === FieldCalculationType.FIXED) {
        const balloonAmount = formData.balloonAmount || 0;
        setFormData((state) => ({
          ...state,
          balloonPercentage: ((balloonAmount / purchasePrice) * 100).toFixed(2),
        }));
      }
    }
  }, [
    purchasePrice,
    formData.balloonType,
    formData.balloonPercentage,
    formData.balloonAmount,
  ]);

  const onChangeBalloon = (name, value) => {
    if (formData[name] === value) return;

    setFormData((state) => ({
      ...state,
      [name]: value,
      balloonType:
        name === "balloonPercentage"
          ? FieldCalculationType.PERCENTAGE
          : FieldCalculationType.FIXED,
    }));
  };

  const calculateBrokerage = useCallback(() => {
    if (netAmountFinanced) {
      if (formData.brokerageType === FieldCalculationType.PERCENTAGE) {
        const brokeragePercentage = formData.brokeragePercentage || 0;

        setFormData((state) => ({
          ...state,
          brokerageAmount: (
            (brokeragePercentage / 100) *
            netAmountFinanced
          ).toFixed(2),
        }));
      }

      if (formData.brokerageType === FieldCalculationType.FIXED) {
        const brokerageAmount = formData.brokerageAmount || 0;
        setFormData((state) => ({
          ...state,
          brokeragePercentage: (
            (brokerageAmount / netAmountFinanced) *
            100
          ).toFixed(2),
        }));
      }
    }
  }, [
    netAmountFinanced,
    formData.brokerageType,
    formData.brokeragePercentage,
    formData.brokerageAmount,
  ]);

  const onChangeBrokerage = (name, value) => {
    if (formData[name] === value) return;

    setFormData((state) => ({
      ...state,
      [name]: value,
      brokerageType:
        name === "brokeragePercentage"
          ? FieldCalculationType.PERCENTAGE
          : FieldCalculationType.FIXED,
    }));
  };

  const calculateBaseLoanAmount = useCallback(() => {
    const purchasePrice =
      parseFloat(formData.purchasePrice.replace(/[^0-9.-]+/g, "")) || 0;
    const payoutAmount =
      parseFloat(formData.payoutAmount.replace(/[^0-9.-]+/g, "")) || 0;
    const tradeIn = parseFloat(formData.tradeIn.replace(/[^0-9.-]+/g, "")) || 0;
    const cashDeposit =
      parseFloat(formData.cashDeposit.replace(/[^0-9.-]+/g, "")) || 0;

    setBaseLoanAmount(
      Math.max(0, purchasePrice + payoutAmount - tradeIn - cashDeposit),
    );
  }, [
    setBaseLoanAmount,
    formData.purchasePrice,
    formData.payoutAmount,
    formData.tradeIn,
    formData.cashDeposit,
  ]);

  const calculateNetAmountFinanced = useCallback(() => {
    let netAmount = baseLoanAmount;
    const loanApplicationFee =
      parseFloat(formData.loanApplicationFee.replace(/[^0-9.-]+/g, "")) || 0;
    const brokerOriginationFees =
      parseFloat(formData.brokerOriginationFees.replace(/[^0-9.-]+/g, "")) || 0;

    if (formData.financeApplicationFee) {
      netAmount += loanApplicationFee * 1.1;
    }

    if (formData.financeBrokerageFee) {
      netAmount += brokerOriginationFees * 1.1;
    }

    setNetAmoundFinanced(netAmount);
  }, [
    baseLoanAmount,
    setNetAmoundFinanced,
    formData.financeApplicationFee,
    formData.loanApplicationFee,
    formData.financeBrokerageFee,
    formData.brokerOriginationFees,
  ]);

  useEffect(() => {
    calculateBaseLoanAmount();
  }, [calculateBaseLoanAmount]);

  useEffect(() => {
    calculateNetAmountFinanced();
  }, [calculateNetAmountFinanced]);

  useEffect(() => {
    calculateBalloon();
  }, [calculateBalloon]);

  useEffect(() => {
    calculateBrokerage();
  }, [calculateBrokerage]);

  return (
    <div className="container">
      <div>
        <QuestLogo />
        <div className="container">
          <div className="quote-form">
            <h2>Quote</h2>
            <form>
              <div className="input-row">
                <div className="input-column">
                  <InputField
                    label="Purchase price *"
                    type="text"
                    format="money"
                    onChange={handleInputChange}
                    value={formData.purchasePrice}
                    name="purchasePrice"
                  />
                </div>
                <div className="input-column">
                  <InputField
                    label="Cash deposit"
                    type="text"
                    format="money"
                    onChange={handleInputChange}
                    value={formData.cashDeposit}
                    name="cashDeposit"
                  />
                </div>
              </div>
              <div className="input-row">
                <div className="input-column">
                  <InputField
                    label="Trade in"
                    type="text"
                    format="money"
                    onChange={handleInputChange}
                    value={formData.tradeIn}
                    name="tradeIn"
                  />
                </div>
                <div className="input-column">
                  <InputField
                    label="Payout amount"
                    type="text"
                    format="money"
                    onChange={handleInputChange}
                    value={formData.payoutAmount}
                    name="payoutAmount"
                  />
                </div>
              </div>
              <div className="full-width-frame">
                <label className="text-wrapper">Balloon</label>
                <div className="input-split">
                  <div className="input-wrapper">
                    <InputField
                      label=""
                      type="text"
                      format="percentage"
                      onChange={onChangeBalloon}
                      value={formData.balloonPercentage}
                      name="balloonPercentage"
                    />
                  </div>
                  <div className="input-wrapper">
                    <InputField
                      label=""
                      type="text"
                      format="money"
                      onChange={onChangeBalloon}
                      value={formData.balloonAmount}
                      name="balloonAmount"
                    />
                  </div>
                </div>
              </div>
              <div className="full-width-frame">
                <label className="text-wrapper">Brokerage (ex GST)</label>
                <div className="input-split">
                  <div className="input-wrapper">
                    <InputField
                      label=""
                      type="text"
                      format="percentage"
                      onChange={onChangeBrokerage}
                      value={formData.brokeragePercentage}
                      name="brokeragePercentage"
                    />
                  </div>
                  <div className="input-wrapper">
                    <InputField
                      label=""
                      type="text"
                      format="money"
                      onChange={onChangeBrokerage}
                      value={formData.brokerageAmount}
                      name="brokerageAmount"
                    />
                  </div>
                </div>
              </div>
              <InputField
                label="Financier rate"
                placeholder="%"
                type="text"
                format="percentage"
                onChange={handleInputChange}
                value={formData.financierRate}
                name="financierRate"
              />
              <div className="spacer"></div> {/* Add spacing after Term */}
              <InputField
                label="Term (years)"
                type="radio"
                name="term"
                options={[
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                  { value: "4", label: "4" },
                  { value: "5", label: "5" },
                  { value: "6", label: "6" },
                  { value: "7", label: "7" },
                ]}
                onChange={handleInputChange}
                value={formData.term}
              />
              <div className="spacer" />
              <InputField
                label="Payment cycle"
                type="toggle"
                toggle={{
                  options: ["Monthly", "Weekly"],
                  initialOption: formData.paymentCycle || "Monthly",
                }}
                onChange={handleInputChange}
                name="paymentCycle"
              />
              <div className="spacer" />
              <InputField
                label="Payment type"
                type="toggle"
                toggle={{
                  options: ["Advance", "Arrears"],
                  initialOption: formData.paymentTime || "Advance",
                }}
                onChange={handleInputChange}
                name="paymentTime"
              />
              <div className="spacer" />
              <div className="input-row">
                <div className="input-column">
                  <InputField
                    label="Application fee (ex GST)"
                    type="text"
                    format="money"
                    onChange={handleInputChange}
                    value={formData.loanApplicationFee}
                    name="loanApplicationFee"
                  />
                </div>
                <div className="input-column">
                  <InputField
                    label="Origination fee (ex GST)"
                    type="text"
                    format="money"
                    onChange={handleInputChange}
                    value={formData.brokerOriginationFees}
                    name="brokerOriginationFees"
                  />
                </div>
              </div>
              <div className="text-wrapper mt-4 mb-3">
                Which of these fees would you like to finance?
              </div>
              <div className="checkbox-row">
                <div className="checkbox-column">
                  <InputField
                    label="Finance application fee"
                    type="checkbox"
                    checked={formData.financeApplicationFee}
                    onChange={handleInputChange}
                    name="financeApplicationFee"
                  />
                </div>
                <div className="checkbox-column">
                  <InputField
                    label="Finance origination fee"
                    type="checkbox"
                    checked={formData.financeBrokerageFee}
                    onChange={handleInputChange}
                    name="financeBrokerageFee"
                  />
                </div>
              </div>
            </form>
          </div>

          <QuoteSummary formData={formData} />
        </div>
      </div>
    </div>
  );
}

export default Desktop;
