import React, { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import "../../template/header/header.scss";

const Header = () => {
  const companyName = window.localStorage.getItem("companyName");
  const companyLogo = window.localStorage.getItem("companyLogo");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search) {
      const queryVar = new URLSearchParams(location.search);
      const companyName = queryVar.get("companyName");
      const companyLogo = queryVar.get("companyLogo");
      const companyBanner = queryVar.get("companyBanner");

      if (companyName) window.localStorage.setItem("companyName", companyName);
      if (companyLogo) window.localStorage.setItem("companyLogo", companyLogo);
      if (companyBanner)
        window.localStorage.setItem("companyBanner", companyBanner);

      queryVar.delete("companyName");
      queryVar.delete("companyLogo");
      queryVar.delete("companyBanner");
      queryVar.delete("source");

      const newParam = queryVar.toString();
      navigate(`/roi-calculator?${newParam}`);
    }
  }, [navigate, location.search]);

  return (
    Boolean(companyName || companyLogo) && (
      <div className="template-header" data-testid="template-header">
        <div className="inner">
          {!!companyName && (
            <div className="company" data-testid="company-name">
              {companyName}
            </div>
          )}
          {!!companyLogo && (
            <img
              src={companyLogo}
              alt={companyName}
              className="logo"
              data-testid="company-logo"
            />
          )}
        </div>
      </div>
    )
  );
};

export default Header;
