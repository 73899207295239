import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const InfoBox = ({ totalCommission }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const formatMoney = (value) => {
    // Handle various input types (string, number, or undefined)
    const number =
      typeof value === "string"
        ? parseFloat(value.replace(/[^0-9.-]+/g, ""))
        : value;

    // Return formatted string or empty string if invalid
    return isNaN(number)
      ? ""
      : `$${number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const styles = {
    infoBox: {
      backgroundColor: isMobile ? "white" : "white",
      padding: "15px",
      borderRadius: "8px",
      marginTop: "20px",
    },
    title: {
      color: "#007BFF",
      fontWeight: "bold",
      marginBottom: "10px",
      backgroundColor: "white",
      fontSize: "24px",
    },
    text: {
      color: "#FF8E00",
      fontSize: "18px",
    },
    link: {
      color: "#007BFF",
      textDecoration: "underline",
    },
  };

  return (
    <div style={styles.infoBox}>
      <p style={styles.title}>Did you know?</p>
      <p style={styles.text}>
        You could earn {formatMoney(totalCommission)} by funding this deal
        yourself using Quest.{" "}
        <a
          href="https://quest.finance/saas-platform/finance-brokers/"
          style={styles.link}
          target="_blanck"
          rel="noopener noreferrer"
        >
          <b> Find out how</b>
        </a>
      </p>
    </div>
  );
};

InfoBox.propTypes = {
  formData: PropTypes.object,
  totalCommission: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalInterest: PropTypes.number,
  totalEarnedByBroker: PropTypes.number,
};

export default InfoBox;
