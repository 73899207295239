import React, { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row, Input, Select, Button, message, Spin } from "antd";
import axios from "axios";
import cs from "classnames";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import _get from "lodash.get";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";
import { useDebouncedCallback } from "use-debounce";
import { useLocation, useNavigate } from "react-router-dom";

import { convertToCurrency } from "../../../shared/helpers/";
import Footer from "../../components/template/footer/footer";
import Header from "../../components/template/header/header";
import {
  PROVIDER_LABEL,
  defaultValue,
  validationSchema,
  loanTerms,
} from "../../constants/roi-calc";
import "../../components/calc/calc.scss";
import "./calc.scss";

const API_URL = process.env.REACT_APP_API_URL;
const { Option } = Select;
const ROI_CALC_NOTIF = "ROI_CALC_NOTIF";

const roundValue = (value) =>
  convertToCurrency(value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const CalcRow = ({ label, values, highlight, cols = 3, ...props }) => {
  return (
    <div className="calc-row">
      <Row gutter={[32, 16]} align="middle">
        <Col span={9}>
          <span className="row-label">{label}</span>
        </Col>
        {Array.from({ length: cols }, (_, i) => {
          const testId = props.testId + "-" + i;
          let value = _get(values, i, 0);

          return (
            <Col span={5} key={i}>
              <div
                className={cs("val", { hl: highlight })}
                data-testid={testId}
              >
                {roundValue(value)}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const CalcTreatment = ({ fieldName, control, errors, data, loading }) => {
  return (
    <>
      <div className="head">
        <Row gutter={[32, 16]} align="middle">
          <Col span={15}>
            <span className="row-label">No. of treatments per week</span>
          </Col>
          <Col span={9}>
            <div className="week">
              <NumberInput
                fieldName={fieldName}
                control={control}
                errors={errors}
                inputProps={{
                  className: "week",
                  "data-testid": fieldName,
                  maxLength: 4,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Spin spinning={loading}>
        <div className="treatment sm">
          <div className="calc-row">
            <Row gutter={[32, 16]} align="middle">
              <Col span={15}>
                <span className="row-label">Equipment cost per month</span>
              </Col>
              <Col span={9}>
                <div className="val">{roundValue(data?.costPerMonth ?? 0)}</div>
              </Col>
            </Row>
          </div>
          <div className="calc-row">
            <Row gutter={[32, 16]} align="middle">
              <Col span={15}>
                <span className="row-label">Treatment charge per month</span>
              </Col>
              <Col span={9}>
                <div className="val">
                  {roundValue(data?.treatmentChargePerMonth ?? 0)}
                </div>
              </Col>
            </Row>
          </div>
          <div className="calc-row">
            <Row gutter={[32, 16]} align="middle">
              <Col span={15}>
                <span className="row-label">Gross profit per month</span>
              </Col>
              <Col span={9}>
                <div className="val">
                  {roundValue(data?.grossProfitPerMonth ?? 0)}
                </div>
              </Col>
            </Row>
          </div>
          <div className="calc-row">
            <Row gutter={[32, 16]} align="middle">
              <Col span={14}>
                <span className="row-label">Gross profit per year</span>
              </Col>
              <Col span={10}>
                <div className="val hl">
                  {roundValue(data?.grossProfitPerYear ?? 0)}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
};

const AmountInput = React.memo(({ fieldName, control, errors, testId }) => (
  <Controller
    name={fieldName}
    control={control}
    render={({ field: { value, onChange } }) => {
      return (
        <NumericFormat
          value={value}
          thousandSeparator=","
          decimalScale={2}
          allowNegative={false}
          onValueChange={({ floatValue }) => onChange(floatValue || "")}
          inputMode="decimal"
          // Props below are Ant design specific
          customInput={Input}
          addonBefore="$"
          status={errors[fieldName] ? "error" : ""}
          data-testid={testId}
        />
      );
    }}
  />
));

const NumberInput = ({ fieldName, control, errors, inputProps }) => (
  <Controller
    name={fieldName}
    control={control}
    render={({ field: { value, onChange } }) => {
      return (
        <NumericFormat
          value={value}
          allowNegative={false}
          onValueChange={({ value }) => onChange(value || "")}
          inputMode="decimal"
          // Props below are Ant design specific
          customInput={Input}
          status={errors[fieldName] ? "error" : ""}
          {...inputProps}
        />
      );
    }}
  />
);

const RoiCalculator = () => {
  const [calcResult, setCalcResult] = useState({});
  const [calculating, setCalculating] = useState(false);
  const [loading, setLoading] = useState(false);
  const companyName = window.localStorage.getItem("companyName") || "";
  const companyLogo = window.localStorage.getItem("companyLogo") || "";
  const companyBanner = window.localStorage.getItem("companyBanner") || "";
  const mounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    formState,
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    mode: "onChange",
  });
  const isMobileView = useMediaQuery({
    query: "(max-width: 767.98px)",
  });
  const formValues = watch();

  const {
    description,
    cost,
    loanTerm,
    provider,
    chargePerTreatment,
    "week-1": week1,
    "week-2": week2,
    "week-3": week3,
  } = formValues;
  const treatmentsPerWeek = [+week1, +week2, +week3];

  const sendApiReq = (params) =>
    axios.post(API_URL + "/lead/roi/calculate", {
      description,
      treatmentsPerWeek,
      cost: +cost,
      loanTerm: +loanTerm * 12,
      provider: formValues.provider,
      chargePerTreatment: +chargePerTreatment,
      customerName: formValues.customerName,
      company: {
        name: companyName,
        logo: companyLogo,
        banner: companyBanner,
      },
      ...params,
    });

  const calc = async () => {
    try {
      const { data } = await sendApiReq();
      setCalcResult(data);
    } catch (e) {
      message.open({
        key: ROI_CALC_NOTIF,
        type: "error",
        content: "Something went wrong. Please try again",
      });
    }
    setCalculating(false);
  };

  const onSubmit = async (...args) => {
    setLoading(true);
    try {
      const { data } = await sendApiReq({
        exportToPdf: true,
        company: {
          name: companyName,
          logoUrl: companyLogo,
          bannerUrl: companyBanner,
        },
      });
      const resp = await axios.get(
        API_URL + `/lead/roi/download/` + data.fileName,
      );
      const file = await axios.get(resp.data.fileUrl, {
        responseType: "blob",
      });
      fileDownload(
        file.data,
        `ROI Quote ${dayjs().format("MMM DD, YYYY")}.pdf`,
      );
    } catch (e) {
      message.open({
        key: ROI_CALC_NOTIF,
        type: "error",
        content: "Something went wrong. Please try again",
      });
    }
    setLoading(false);
  };

  const debouncedCalc = useDebouncedCallback(() => {
    calc();
  }, 500);

  useEffect(() => {
    setValue("week-1", week1, { shouldValidate: true });
    setValue("week-2", week2, { shouldValidate: true });
    setValue("week-3", week3, { shouldValidate: true });
  }, [isMobileView, setValue, week1, week2, week3]);

  useEffect(() => {
    if (formState.submitCount) {
      const errorField = document.querySelector(".ant-input-status-error");
      if (errorField) {
        // errorField.focus();
      }
    }
  }, [formState.submitCount, isMobileView, errors]);

  useEffect(() => {
    const func = async () => {
      if (formState.isDirty) {
        const result = await trigger();
        setCalculating(result);

        if (result) {
          debouncedCalc({
            cost,
            loanTerm,
            chargePerTreatment,
          });
        }
      }
    };

    func();
  }, [
    debouncedCalc,
    cost,
    loanTerm,
    chargePerTreatment,
    provider,
    week1,
    week2,
    week3,
    formState.isDirty,
    trigger,
  ]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <div className="qf-template calc-page">
      <Header />
      <div className="template-body">
        <div className="calc">
          <div className="heading">
            <div className="title">
              ROI Calculator <span className="dash">-</span>{" "}
              <span className="line-2">Let's talk numbers</span>
            </div>
            <div className="quest-logo">
              <img src="/assets/images/quest-logo-white.svg" alt="Quest" />
            </div>
          </div>
          <div className="body">
            <div className="form" data-testid="calc-form">
              <Row gutter={[32, 16]}>
                <Col xs={24} lg={8} className="grp">
                  <div className="grp-name">Device details</div>
                  <div className="field">
                    <div className="label">
                      Device name <span className="info">(optional)</span>
                    </div>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Input
                            value={value}
                            onChange={onChange}
                            data-testid="description"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="field">
                    <div className="label">Device price ex GST</div>
                    <AmountInput
                      fieldName="cost"
                      control={control}
                      errors={errors}
                      testId="cost"
                    />
                  </div>
                </Col>
                <Col xs={24} lg={8} className="grp">
                  <div className="grp-name">Financing options</div>
                  <Controller
                    name="loanTerm"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <div className="field">
                          <div className="label">
                            Loan term <span className="info">(years)</span>
                          </div>
                          <Select
                            value={value}
                            onSelect={(selected) => onChange(selected)}
                            className="w-100"
                            data-testid="loan-term"
                            popupClassName="loan-term-options"
                          >
                            {loanTerms.map((v) => (
                              <Option value={v} key={v}>
                                {v}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      );
                    }}
                  />
                  <div className="field">
                    <div className="label">Client type</div>
                    <Controller
                      name="provider"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Select
                            value={value}
                            onSelect={(v) => onChange(v)}
                            className="w-100"
                            data-testid="provider"
                            popupClassName="provider-options"
                          >
                            {Object.entries(PROVIDER_LABEL).map(
                              ([key, value]) => {
                                return (
                                  <Option value={key} key={key}>
                                    {value}
                                  </Option>
                                );
                              },
                            )}
                          </Select>
                        );
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24} lg={8} className="grp">
                  <div className="grp-name">Cost per treatment</div>
                  <div className="field">
                    <div className="label">Approx charge per treatment?</div>
                    <AmountInput
                      fieldName="chargePerTreatment"
                      control={control}
                      errors={errors}
                      testId="charge-per-treatment"
                    />
                  </div>
                  <div className="field">
                    <div className="label">
                      Business or Customer name{" "}
                      <span className="info">(optional)</span>
                    </div>
                    <Controller
                      name="customerName"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Input
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            data-testid="customer-name"
                          />
                        );
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {!isMobileView ? (
              <div className="quote-result lg">
                <div className="scenario grp-name">
                  <Row gutter={[32, 16]} align="middle">
                    <Col span={9}>
                      <span className="row-label">Compare 3 Scenarios</span>
                    </Col>
                    <Col span={5} className="week">
                      <span>Scenario 1</span>
                    </Col>
                    <Col span={5} className="week">
                      <span>Scenario 2</span>
                    </Col>
                    <Col span={5} className="week">
                      <span>Scenario 3</span>
                    </Col>
                  </Row>
                </div>

                <div className="head">
                  <Row gutter={[32, 16]} align="middle">
                    <Col span={9}>
                      <span className="row-label">
                        No. of treatments per week
                      </span>
                    </Col>
                    {treatmentsPerWeek.map((count, i) => {
                      const name = `week-${i + 1}`;
                      return (
                        <Col span={5} key={i}>
                          <NumberInput
                            fieldName={name}
                            control={control}
                            errors={errors}
                            inputProps={{
                              className: "week",
                              "data-testid": name,
                              maxLength: 4,
                            }}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <Spin spinning={calculating} data-testid="loading-overlay">
                  <div className="treatment">
                    <CalcRow
                      label="Finance payment per Month"
                      values={new Array(treatmentsPerWeek.length).fill(
                        calcResult.costPerMonth,
                      )}
                      testId="device-cost"
                    />
                    <CalcRow
                      label="Treatment revenue per Month"
                      values={calcResult.computationPerTreatment?.map(
                        (v) => v.treatmentChargePerMonth,
                      )}
                      testId="treatment-revenue"
                    />
                    <CalcRow
                      label="Gross profit per Month"
                      values={calcResult.computationPerTreatment?.map(
                        (v) => v.grossProfitPerMonth,
                      )}
                      testId="gross-profit-month"
                    />
                    <CalcRow
                      label="Gross profit per Year"
                      values={calcResult.computationPerTreatment?.map(
                        (v) => v.grossProfitPerYear,
                      )}
                      highlight={true}
                      testId="gross-profit-year"
                    />
                  </div>
                </Spin>
              </div>
            ) : (
              <div className="quote-result sm">
                <Col className="grp-name">
                  <span className="scenario-sm">Compare 3 Scenarios</span>
                </Col>
                {treatmentsPerWeek.map((_, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Col className="grp-name">
                        <span className="week">Scenario {i + 1}</span>
                      </Col>
                      <CalcTreatment
                        loading={calculating}
                        fieldName={`week-${i + 1}`}
                        control={control}
                        errors={errors}
                        data={{
                          costPerMonth: calcResult.costPerMonth,
                          ...calcResult?.computationPerTreatment?.[i],
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            <div className="button-spacing">
              <Button
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                size="large"
                className="qf-btn print-quote-btn"
                data-testid="print-button"
              >
                Print Quote
              </Button>
              <Button
                onClick={() => {
                  const queryString = location.search
                    ? `&${location.search.substring(1)}`
                    : "";
                  navigate(`/?source=roi_calculator${queryString}`);
                }}
                size="large"
                className="qf-btn"
                data-testid="apply-button"
              >
                Apply Now
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RoiCalculator;
